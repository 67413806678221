<template>
  <section
    v-if="Object.keys(pricing).length"
    id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-1">
        {{ $t('prc.pricingPlans') }}
      </h1>
      <p class="mb-2 pb-75">
        {{ $t('prc.chooseTheBestPlan') }}
      </p>
      <div
        v-if="showAnnualPlan"
        class="d-flex align-items-center justify-content-center mb-5 pb-50">
        <h6
          class="mr-1 mb-0 swprice"
        >
          {{ $t('prc.monthly') }}
        </h6>
        <b-form-checkbox
          id="priceSwitch"
          v-model="status"
          name="price-switch"
          value="annually"
          unchecked-value="monthly"
          switch
          @input="tooglePlan"
        />
        <h6
          class="ml-50 mb-0 swprice"
        >
          {{ $t('prc.annually') }}, <span class="bolded">{{ $t('prc.saveUpTo', { percents: 20 } ) }}</span>
        </h6>
      </div>
    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row>
          <b-col md="4">
            <b-card
              align="center"
            >
              <!-- img -->
              <b-img
                v-if="pricing.basicPlan.img"
                :src="pricing.basicPlan.img"
                class="mb-2 mt-5"
                alt="basic svg img"
              />
              <!--/ img -->
              <h3>{{ pricing.basicPlan.title }}</h3>
              <b-card-text>
                {{ pricing.basicPlan.subtitle }}
              </b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow ? pricing.basicPlan.monthlyPrice:pricing.basicPlan.yearlyPlan.perMonth }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ $t('prc.month') }}</sub>
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                >EUR {{ pricing.basicPlan.yearlyPlan.totalAnual }} / {{ $t('prc.year') }}</small>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data,index) in pricing.basicPlan.planBenefits"
                  :key="index"
                >
                  <span v-html="data" />
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                block
                class="mt-2"
                :variant="userData.subscription_code == 1 ? 'outline-success' : 'primary'"
                @click="routeToProfile(0)"
              >
              <span v-html="getPhraseForPlanName(1)" />
              </b-button>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card
              class="popular"
              align="center"
            >
              <div
                v-show="pricing.standardPlan.popular"
                class="pricing-badge text-right"
              >
                <b-badge
                  variant="light-primary"
                  pill
                >
                  {{ $t('prc.popular') }}
                </b-badge>
              </div>
              <!-- img -->
              <b-img
                v-if="pricing.standardPlan.img"
                :src="pricing.standardPlan.img"
                class="mb-1"
                alt="svg img"
              />
              <!--/ img -->
              <h3>{{ pricing.standardPlan.title }}</h3>
              <b-card-text>{{ pricing.standardPlan.subtitle }}</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow ? pricing.standardPlan.monthlyPrice:pricing.standardPlan.yearlyPlan.perMonth }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ $t('prc.month') }}</sub>
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                >EUR {{ pricing.standardPlan.yearlyPlan.totalAnual }} / {{ $t('prc.year') }}</small>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data,index) in pricing.standardPlan.planBenefits"
                  :key="index"
                >
                  <span v-html="data" />
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2"
                :variant="userData.subscription_code == 2 ? 'outline-success' : 'primary'"
                @click="routeToProfile(1)"
              >
                <span v-html="getPhraseForPlanName(2)" />
              </b-button>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card
              class=""
              align="center"
            >
              <div
                v-show="pricing.premiumPlan.popular"
                class="pricing-badge text-right"
              >
                <b-badge
                  variant="light-primary"
                  pill
                >
                  Popular
                </b-badge>
              </div>
              <!-- img -->
              <b-img
                v-if="pricing.premiumPlan.img"
                :src="pricing.premiumPlan.img"
                class="mb-1"
                alt="svg img"
              />
              <!--/ img -->
              <h3>{{ pricing.premiumPlan.title }}</h3>
              <b-card-text>{{ pricing.premiumPlan.subtitle }}</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow ? pricing.premiumPlan.monthlyPrice:pricing.premiumPlan.yearlyPlan.perMonth }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ $t('prc.month') }}</sub>
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                >EUR {{ pricing.premiumPlan.yearlyPlan.totalAnual }} / {{ $t('prc.year') }}</small>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data,index) in pricing.premiumPlan.planBenefits"
                  :key="index"
                >
                  <span v-html="data" />
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2"
                :variant="userData.subscription_code == 3 ? 'outline-success' : 'primary'"
                @click="routeToProfile(2)"
              >
                <span v-html="getPhraseForPlanName(3)" />
              </b-button>
            </b-card>
          </b-col>

        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->

    <!-- pricing free trial -->
    <div
      v-if="!userData.is_premium"
      class="pricing-free-trial"
    >
      <b-row>
        <b-col
          lg="10"
          offset-lg="3"
          class="mx-auto"
        >
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                {{ $t('prc.studyMore') }}
              </h3>
              <h5>{{ $t('prc.youWillGet') }}</h5>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mt-lg-3"
                @click="routeToProfile(2)"
              >
                {{ $t('prc.getPremium') }}
              </b-button>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing free trial -->

    <div
      v-if="userData.is_premium"
      class="mb-3"
    />

    <!-- pricing faq -->
    <div class="pricing-faq">
      <h3 class="text-center">
        {{ $t('prc.faqs') }}
      </h3>
      <p class="text-center">
        {{ $t('prc.faqUndertitle') }}
      </p>
      <b-row class="py-2">
        <b-col
          lg="10"
          offset-lg="2"
          class="mx-auto"
        >
          <app-collapse
            accordion
            type="margin"
          >

            <app-collapse-item
              v-for="(data,index) in pricing.qandA"
              :key="index"
              :title="data.question"
            >
              {{ data.ans }}
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing faq -->
  </section>
</template>

<script>
import {
  BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showAnnualPlan: false,
      userData: JSON.parse(localStorage.getItem('userData')),
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {
        basicPlan: {
          title: this.$t('prc.basicPlan.title'),
          img: require('@/assets/images/illustration/Pot1.svg'),
          subtitle: this.$t('prc.basicPlan.subtitle'),
          monthlyPrice: 6,
          yearlyPlan: {
            perMonth: 5,
            totalAnual: 60,
          },
          planBenefits: [
            this.$t('prc.basicPlan.benefits.maxMessages', { messages: 250 }),
            this.$t('prc.basicPlan.benefits.1'),
            this.$t('prc.basicPlan.benefits.2'),
            this.$t('prc.basicPlan.benefits.3'),
            this.$t('prc.basicPlan.benefits.4'),
            this.$t('prc.basicPlan.benefits.5'),
          ],
          popular: false,
        },
        standardPlan: {
          title: this.$t('prc.standardPlan.title'),
          img: require('@/assets/images/illustration/Pot2.svg'),
          subtitle: this.$t('prc.standardPlan.subtitle'),
          monthlyPrice: 15,
          yearlyPlan: {
            perMonth: 12.5,
            totalAnual: 150,
          },
          planBenefits: [
            this.$t('prc.standardPlan.benefits.maxMessages', { messages: 800 }),
            this.$t('prc.standardPlan.benefits.1'),
            this.$t('prc.standardPlan.benefits.2'),
            this.$t('prc.standardPlan.benefits.3'),
            this.$t('prc.standardPlan.benefits.4'),
            this.$t('prc.standardPlan.benefits.5'),
          ],
          popular: true,
        },
        premiumPlan: {
          title: this.$t('prc.premiumPlan.title'),
          img: require('@/assets/images/illustration/Pot3.svg'),
          subtitle: this.$t('prc.premiumPlan.subtitle'),
          monthlyPrice: 50,
          yearlyPlan: {
            perMonth: 40,
            totalAnual: 480,
          },
          planBenefits: [
            this.$t('prc.premiumPlan.benefits.maxMessages', { messages: 2500 }),
            this.$t('prc.premiumPlan.benefits.1'),
            this.$t('prc.premiumPlan.benefits.2'),
            this.$t('prc.premiumPlan.benefits.3'),
            this.$t('prc.premiumPlan.benefits.4'),
            this.$t('prc.premiumPlan.benefits.5'),
          ],
          popular: false,
        },
        qandA: [
          {
            question: this.$t('prc.qandA.1.q'),
            ans: this.$t('prc.qandA.1.a'),
          },
          {
            question: this.$t('prc.qandA.2.q'),
            ans: this.$t('prc.qandA.2.a'),
          },
          {
            question: this.$t('prc.qandA.3.q'),
            ans: this.$t('prc.qandA.3.a'),
          },
          {
            question: this.$t('prc.qandA.4.q'),
            ans: this.$t('prc.qandA.4.a'),
          },
          {
            question: this.$t('prc.qandA.5.q'),
            ans: this.$t('prc.qandA.5.a'),
          },
          {
            question: this.$t('prc.qandA.6.q'),
            ans: this.$t('prc.qandA.6.a'),
          },
          {
            question: this.$t('prc.qandA.7.q'),
            ans: this.$t('prc.qandA.7.a'),
          },
          {
            question: this.$t('prc.qandA.8.q'),
            ans: this.$t('prc.qandA.8.a'),
          },
        ],
      },

    }
  },
  created() {
    // this.$http.get('/pricing/data').then(res => { this.pricing = res.data })
  },
  methods: {
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
    routeToProfile(goToPlanIndex) {
      let plan = goToPlanIndex
      // if (goToPlanIndex > 0) {
      //   if (!this.monthlyPlanShow) {
      //     plan += 2
      //   }
      // } else {
      //   plan += 2
      // } // TODO here when Yearly plan will be added
      plan = goToPlanIndex + 1

      this.$router.push({ name: 'pages-profile', params: { planIndex: plan } })
    },
    getPhraseForPlanName(planid) {
      if (this.userData.is_premium) {
        if (planid === this.userData.subscription_code) {
          return this.$t('prc.yourCurrentPlan')
        }
        return this.$t('prc.upgrade')
      }
      return this.$t('prc.upgrade')
    },

  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style>
  .bolded {
    /*font-weight: bold;*/
    color: #00cfe8;
  }

  h6.swprice {
    font-size: 1.2rem;
  }
</style>
